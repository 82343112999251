import React, { Component } from "react"

/* Components */
import { Menu, MobileTab, Header, Background } from '../components'

/* Router */
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

/* Helpers */
import { ProtectedRoute } from '../helpers'

/* SOCKET.IO */
import { io } from 'socket.io-client'

/* Constants */
import { env } from '../constants'

/* Pages */
import Page404 from "../pages/Page404"
import { Dashboard } from '../pages/Dashboard'
import { Stream } from '../pages/Stream'
import { Monitor } from '../pages/Monitor'
import { Games, GameCreate, RMPGame, OMPGame, RVPGame, QMPGame, EMPGame, QVPGame, EVPGame, UMPGame, HMPGame, REPGame, OEPGame, QEPGame, UEPGame, HEPGame, EEPGame } from '../pages/Game'
import { Profile, Password, History } from '../pages/Profile'
import { Dealers, DealerCreate, DealerEdit } from '../pages/Dealer'
import { Currencies, CurrencyCreate, CurrencyEdit } from '../pages/Currency'
import { Cards, CardEdit } from '../pages/Card'
import { Messages } from '../pages/Message'
import { Players } from '../pages/Player'
import { Roles, RoleCreate, RoleEdit } from '../pages/Role'
import { Admins, AdminCreate, AdminEdit, AdminChangePassword, AdminHistory, AdminActivity, AdminAccept } from '../pages/Admin'
import { Notifications } from '../pages/Notification'



/* Layout Widget */
class Layout extends Component {

    constructor() {
        super()

        const minimized = localStorage.getItem("minimized")
        const token = localStorage.getItem("token")

        this.state = {
            minimized: minimized && minimized === "yes"
        }

        this.socket = io(env.socketpoint, { auth: { token: `${token}`, type: `admin` } })
    }

    componentWillUnmount = () => {
        this.socket.disconnect()
    }

    /* Toggle Menu */
    toggleMenu = value => {

        this.setState({ minimized: value })

        if (value) {
            localStorage.setItem("minimized", "yes")
        }
        else {
            localStorage.removeItem("minimized")
        }

    }


    render = () => {

        const { minimized } = this.state
        const { setToken } = this.props

        return (
            <Router>
                <div className="app">

                    <Menu minimized={minimized} toggle={() => this.toggleMenu(!minimized)} />

                    <div className={`container ${minimized ? 'minimized' : ''}`}>

                        <Header socket={this.socket} setToken={setToken} />

                        <Routes>

                            <Route path="*" element={<ProtectedRoute setToken={setToken}><Page404 /></ProtectedRoute>} />
                            <Route path="/" element={<ProtectedRoute setToken={setToken}><Dashboard /></ProtectedRoute>} />

                            {/* Game */}
                            <Route path="/notifications" element={<ProtectedRoute setToken={setToken}><Notifications /></ProtectedRoute>} />
                            <Route path="/notifications/:page" element={<ProtectedRoute setToken={setToken}><Notifications /></ProtectedRoute>} />

                            {/* RUSSIAN MULTIPOKER */}
                            <Route path="/table/russian-multipoker/:uid" element={<RMPGame />} />

                            {/* OPEN MULTIPOKER */}
                            <Route path="/table/open-multipoker/:uid" element={<OMPGame />} />

                            {/* QUEEN MULTIPOKER */}
                            <Route path="/table/queen-multipoker/:uid" element={<QMPGame />} />

                            {/* EUROPEAN MULTIPOKER */}
                            <Route path="/table/european-multipoker/:uid" element={<EMPGame />} />

                            {/* ULTIMATE MULTIPOKER */}
                            <Route path="/table/ultimate-multipoker/:uid" element={<UMPGame />} />

                            {/* CASINO HOLDEM */}
                            <Route path="/table/holdem-multipoker/:uid" element={<HMPGame />} />

                            {/* RUSSIAN VIP POKER */}
                            <Route path="/table/russian-vip-poker/:uid" element={<RVPGame />} />

                            {/* RUSSIAN VIP POKER */}
                            <Route path="/table/queen-vip-poker/:uid" element={<QVPGame />} />

                            {/* RUSSIAN VIP POKER */}
                            <Route path="/table/european-vip-poker/:uid" element={<EVPGame />} />

                            {/* RUSSIAN ELECTRON POKER */}
                            <Route path="/table/russian-electron-poker/:uid" element={<REPGame />} />

                            {/* OPEN ELECTRON POKER */}
                            <Route path="/table/open-electron-poker/:uid" element={<OEPGame />} />

                            {/* ELECTRON QUEEN OF SPADES */}
                            <Route path="/table/queen-electron-poker/:uid" element={<QEPGame />} />

                            {/* ELECTRON ULTIMATE TEXAS HOLD'EM */}
                            <Route path="/table/ultimate-electron-poker/:uid" element={<UEPGame />} />

                            {/* ELECTRON EUROPEAN POKER */}
                            <Route path="/table/european-electron-poker/:uid" element={<EEPGame />} />

                            {/* ELECTRON CASINO HOLD'EM */}
                            <Route path="/table/casino-holdem-electron-poker/:uid" element={<HEPGame />} />

                            {/* Game */}
                            <Route path="/game" element={<ProtectedRoute setToken={setToken}><Games /></ProtectedRoute>} />
                            <Route path="/game/create" element={<ProtectedRoute setToken={setToken}><GameCreate /></ProtectedRoute>} />

                            {/* Player Routes */}
                            <Route path="/player" element={<ProtectedRoute setToken={setToken}><Players /></ProtectedRoute>} />
                            <Route path="/player/:page" element={<ProtectedRoute setToken={setToken}><Players /></ProtectedRoute>} />

                            {/* Message Routes */}
                            <Route path="/message" element={<ProtectedRoute setToken={setToken}><Messages /></ProtectedRoute>} />
                            <Route path="/message/:page" element={<ProtectedRoute setToken={setToken}><Messages /></ProtectedRoute>} />

                            {/* Card Routes */}
                            <Route path="/card" element={<ProtectedRoute setToken={setToken}><Cards /></ProtectedRoute>} />
                            <Route path="/card/:page" element={<ProtectedRoute setToken={setToken}><Cards /></ProtectedRoute>} />
                            <Route path="/card/edit/:id" element={<ProtectedRoute setToken={setToken}><CardEdit /></ProtectedRoute>} />

                            {/* Dealer Routes */}
                            <Route path="/dealer" element={<ProtectedRoute setToken={setToken}><Dealers /></ProtectedRoute>} />
                            <Route path="/dealer/:page" element={<ProtectedRoute setToken={setToken}><Dealers /></ProtectedRoute>} />
                            <Route path="/dealer/create" element={<ProtectedRoute setToken={setToken}><DealerCreate /></ProtectedRoute>} />
                            <Route path="/dealer/edit/:id" element={<ProtectedRoute setToken={setToken}><DealerEdit /></ProtectedRoute>} />

                            {/* Currency Routes */}
                            <Route path="/currency" element={<ProtectedRoute setToken={setToken}><Currencies /></ProtectedRoute>} />
                            <Route path="/currency/:page" element={<ProtectedRoute setToken={setToken}><Currencies /></ProtectedRoute>} />
                            <Route path="/currency/create" element={<ProtectedRoute setToken={setToken}><CurrencyCreate /></ProtectedRoute>} />
                            <Route path="/currency/edit/:id" element={<ProtectedRoute setToken={setToken}><CurrencyEdit /></ProtectedRoute>} />

                            {/* Profile Route */}
                            <Route path="/profile" element={<ProtectedRoute setToken={setToken}><Profile /></ProtectedRoute>} />
                            <Route path="/change-password" element={<ProtectedRoute setToken={setToken}><Password /></ProtectedRoute>} />
                            <Route path="/history" element={<ProtectedRoute setToken={setToken}><History /></ProtectedRoute>} />

                            {/* Role */}
                            <Route path="/user" element={<ProtectedRoute setToken={setToken}><Admins /></ProtectedRoute>} />
                            <Route path="/user/:page" element={<ProtectedRoute setToken={setToken}><Admins /></ProtectedRoute>} />
                            <Route path="/user/create" element={<ProtectedRoute setToken={setToken}><AdminCreate /></ProtectedRoute>} />
                            <Route path="/user/edit/:id" element={<ProtectedRoute setToken={setToken}><AdminEdit /></ProtectedRoute>} />
                            <Route path="/user/password/:id" element={<ProtectedRoute setToken={setToken}><AdminChangePassword /></ProtectedRoute>} />
                            <Route path="/user/history/:id" element={<ProtectedRoute setToken={setToken}><AdminHistory /></ProtectedRoute>} />
                            <Route path="/user/activity/:id" element={<ProtectedRoute setToken={setToken}><AdminActivity /></ProtectedRoute>} />
                            <Route path="/user/accept/:id" element={<ProtectedRoute setToken={setToken}><AdminAccept /></ProtectedRoute>} />

                            {/* Role */}
                            <Route path="/role" element={<ProtectedRoute setToken={setToken}><Roles /></ProtectedRoute>} />
                            <Route path="/role/:page" element={<ProtectedRoute setToken={setToken}><Roles /></ProtectedRoute>} />
                            <Route path="/role/create" element={<ProtectedRoute setToken={setToken}><RoleCreate /></ProtectedRoute>} />
                            <Route path="/role/edit/:id" element={<ProtectedRoute setToken={setToken}><RoleEdit /></ProtectedRoute>} />

                            {/* Stream */}
                            <Route path="/stream" element={<ProtectedRoute setToken={setToken}><Stream /></ProtectedRoute>} />

                            {/* Stream */}
                            <Route path="/monitors" element={<ProtectedRoute setToken={setToken}><Monitor /></ProtectedRoute>} />

                        </Routes>

                    </div>

                    <MobileTab />

                </div>

                <Background />

            </Router>
        )
    }

}

export default Layout